// renders all of the components required to
// make the form for completing the data required for the form

<template>
  <div class="main_container_form container generic_floating_container">
    <div class="">
      <div class="modal-title">
        <BookingFormHeader :amount=selected_spot.formated_price :event_name=event_selected.name
                           :people_number=booking.numberPeople :selected_date="new Date(date_selected).toDateString()" />
      </div>
      <HotelGuestCheck />
      <BookerInformationForm />
      <div class="form-control mb-4" style="text-align: left; width: 87%;"
           v-if="property_fetcher.configuration.sap_customer_comprobante_fiscal !== null">
        <div>
          <label class='accept_terms'>
            <input type="checkbox" name="" id="" v-model='requiredComprobante' >
            Do you require a tax receipt?
          </label>
        </div>
        <hr/>
        <div class="row" v-if="requiredComprobante">
          <div class="col-6">
            <Input input_type="text" label_title='Company name' v-model="companyName"
                   :validation="ruleset_validation.CompanyForm.companyName"
                   html_unique="company_name"
                   :required_f='false'
            />
          </div>
          <div class="col-6 ">
            <Input input_type="text" label_title='RNC o Cedula' v-model="rncOrCedula"
                   :validation="ruleset_validation.CompanyForm.rncOrCedula"
                   html_unique="rnc_cedula"
                   maxlength="11"
                   :required_f='false'
            />
          </div>
        </div>
      </div>
      <div class="cancelation_policy">
        <h3 class="form_title">
          Cancel Policy
        </h3>
        <PolicyTerms />

      </div>
      <div class="modal-title" v-if="selected_spot.formated_price">
        <BookingFormFooter :amount=selected_spot.formated_price :event_name=event_selected.name
                           :people_number=booking.numberPeople
                           :selected_date="new Date(date_selected).toDateString()"
                           @storeBooking='storeBooking' v-if="parseFloat(selected_spot.formated_price.replace('$',''))>0.00 && !property_fetcher.configuration.is_company_charge" />

        <BookingFormFooter :amount=selected_spot.formated_price :event_name=event_selected.name
                           :people_number=booking.numberPeople
                           :selected_date="new Date(date_selected).toDateString()"
                           @storeBooking='completeFreeBooking' v-else />
      </div>
    </div>
    <ModalDialog form_title="Cancel Booking"
                 action_btn_render_class="btn btn-danger"
                 action_btn_title="Cancel Booking"
                 :message="'Are you sure you want to cancel the booking for the event: '+event_selected.name+' ?'"
                 action_name="cancelBooking"
                 @performEmit="performEmit"/>
  </div >

</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import BookerInformationForm from '../components/BookerInformationForm.vue'
import GuestForm from '../components/GuestForm.vue'
import BookingFormHeader from '../components/BookingFormHeader.vue'
import BookingFormFooter from '../components/BookingFormFooter.vue'
import PolicyTerms from '../components/PolicyTerms.vue'
import ModalDialog from '../components/modalDialog.vue'
import useVuelidate from '@vuelidate/core'
import HotelGuestCheck from '../components/HotelGuestCheck.vue'
import Input from "@/components/Input.vue";

export default {
  setup: () => ({ v$: useVuelidate() }),
  name:"BookingForm",
  props:{

  },
  components:{
    Input,
    BookerInformationForm,
    GuestForm,
    BookingFormHeader,
    BookingFormFooter,
    PolicyTerms,
    ModalDialog,
    HotelGuestCheck

  },
  computed: {
    ...mapGetters(['guests', 'event_selected', 'booker',
                 'booking', 'selected_spot',
                 'date_selected', 'prop_token',
                 'property_fetcher', 'company_tax_getter',
      'ruleset_validation', 'requiredComprobanteFiscal', 'event_selected', 'textInputOption']),
    requiredComprobante: {
      get() {
        return this.requiredComprobanteFiscal;
      },
      set(value){
        this.$store.dispatch('setRequiredComprobanteFiscal', value);
      },
    },
    companyName: {
      get(){
        return this.company_tax_getter.companyName;
      },
      set(value){
        const sender = {
          key: 'companyName',
          value: value
        };

        this.$store.dispatch('fillCompanyTaxInfo', sender);
      },
    },
    rncOrCedula: {
      get(){
        return this.company_tax_getter.rncOrCedula;
      },
      set(value){
        const sender = {
          key: 'rncOrCedula',
          value: value
        };

        this.$store.dispatch('fillCompanyTaxInfo', sender);
      },
    },
  },
  methods:{
    ...mapActions(['cancelBooking',
      'stageBookingBackend',
      'freeBookingComplete', 'setRequiredComprobanteFiscal', 'calculateTaxInvoice', 'getExchangeRate']),
    performEmit(data){
      /*
          it performs the action based on existing name
          this takes an object with the name of the task
          and the item to process in case there's one.

          need to make this a promise so that it works
          asynchronously.
      */
      this.$store.dispatch('cancelBooking');
      document.getElementById('close_modal_btn').click();
      this.$toast.add({severity:'success',
        summary:'Booking Canceled',
        detail: "Your booking has been successfully canceled",
        life:7000})
      this.$router.push({name:'property_event_list',
        prop_id:this.prop_token});

    },
    async storeBooking(){

      const company_form_validated = await this.$store.dispatch('runValidations', 'CompanyForm');
      const home_address_validated = await this.$store.dispatch('runValidations', 'HomeAddressForm')

      if (this.requiredComprobante && !company_form_validated.passed) {
        // here we use toastr for displaying the error
        this.$toast.add({severity:'error',
          summary:'Error Message',
          detail: full_validated.error,
          life:7000});

        return;
      }

      if(this.event_selected.textOptions?.find(option => option.name.includes(this.textInputOption.homeAddress.identifier))
        && !home_address_validated.passed) {
          // here we use toastr for displaying the error
          this.$toast.add({severity:'error',
            summary:'Error Message',
            detail: full_validated.error,
            life:7000});

          return;
      }

      const full_validated = await this.$store.dispatch('runValidations',
        'BookingForm');
      var send_request = false;

      if(full_validated.passed){
        //    here we validate the fields are not empty and validate the room
        if (this.property_fetcher.configuration.validate_property_guest){
          const fields_validation = await this.$store.dispatch('runValidations',
            'HotelGuestRoom')
          if (fields_validation.passed){
            const final_validation = await this.$store.dispatch('validateHotelGuest');
            if(final_validation.status){
              send_request = true;
            }
            else{

              // here we use toastr for displaying the error
              this.$toast.add({severity:'error',
                summary:'Error Message',
                detail: final_validation.message,
                life:7000})
            }
          }
          else{
            // here we use toastr for displaying the error
            this.$toast.add({severity:'error',
              summary:'Error Message',
              detail: fields_validation.error,
              life:7000})

          }

        }
        else{
          send_request = true;
        }
        if (send_request){
          this.$store.dispatch('stageBookingBackend').then(
            (response)=>{
              if(response.status){
                this.$toast.add({severity:'success',
                  summary:'Booking successfully created!!',
                  detail: response.message,
                  life:7000})

                // here we redirect to the confirmation
                this.$router.push({name:'confirm_booking',
                  prop_id:this.prop_token,
                  event_id:this.event_selected.productId})
              }
              else{
                // here we use toastr for displaying the error
                this.$toast.add({severity:'error',
                  summary:'Error Message',
                  detail: response.message,
                  life:7000})
              }
            }
          )


        }
      }
      else{
        // here we use toastr for displaying the error
        this.$toast.add({severity:'error',
          summary:'Error Message',
          detail: full_validated.error,
          life:7000})

      }
    },

    async completeFreeBooking(){
      /*
     here we need to validate
     all of the fields that have been rendered in the form
     and also need ot send the request so that
     we verify if there's a holding or not with the service.

     also we redirect to the page once this is done properly.

     We need to add the validations to this form

     Need to modify this so that it works
     with the validation and everything else.
     */
      const full_validated = await this.$store.dispatch('runValidations',
        'BookingForm');
      var send_request = false;

      if(full_validated.passed){
        //    here we validate the fields are not empty and validate the room
        if (this.property_fetcher.configuration.validate_property_guest){
          const fields_validation = await this.$store.dispatch('runValidations',
            'HotelGuestRoom')
          if (fields_validation.passed){
            const final_validation = await this.$store.dispatch('validateHotelGuest');
            if(final_validation.status){
              send_request = true;
            }
            else{

              // here we use toastr for displaying the error
              this.$toast.add({severity:'error',
                summary:'Error Message',
                detail: final_validation.message,
                life:7000})
            }
          }
          else{
            // here we use toastr for displaying the error
            this.$toast.add({severity:'error',
              summary:'Error Message',
              detail: fields_validation.error,
              life:7000})

          }

        }
        else{
          send_request = true;
        }
        if (send_request){
          this.$store.dispatch('freeBookingComplete').then(
            (response)=>{
              if(response.status){
                this.$toast.add({severity:'success',
                  summary:'Booking successfully created!!',
                  detail: response.message,
                  life:7000})

                // here we redirect to the confirmation
                this.$router.push({name:'booking_completed',
                  prop_id:this.prop_token,
                  event_id:this.event_selected.productId})
              }
              else{
                // here we use toastr for displaying the error
                this.$toast.add({severity:'error',
                  summary:'Error Message',
                  detail: response.message,
                  life:7000})
              }
            }
          )


        }
      }
      else{
        // here we use toastr for displaying the error
        if (this.property_fetcher.configuration.validate_property_guest){
          const fields_validation = await this.$store.dispatch('runValidations',
            'HotelGuestRoom')
        }
        this.$toast.add({severity:'error',
          summary:'Error Message',
          detail: full_validated.error,
          life:7000})

      }
    }

  },
  mounted() {
    this.$store.dispatch('getExchangeRate').then(() => {
      this.$store.dispatch('calculateTaxInvoice');
    });
  }
}
</script>

<style>
.cancelation_policy{
  text-align: left;
  padding: 5px 5px 5px 5px;

}
.participants{
  text-align: left;
  padding: 5px 5px 5px 5px;
}
.form_title{
  text-align: left;
  padding: 25px 5px -5px 11px;
  color: #8c8c8c;

}
.main_container_form{
  background-color: #f7f7f7;
  padding: 0px 0px 0px 15px;
}
.primary_col{
  max-width: 38%;
}
@media screen and (max-width:900px) {
  .primary_col{
    max-width: 100%;
  }
}

</style>
